import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import VideoPlayer from 'react-player';
import { customPropTypes } from '../../util';

import './InfographicVideo.scss';

import { videoPropTypes } from '../../util/custom-prop-types';
import { useIntersectionObserver, useCSSMotion, useSectionTimeSpent } from '../../util';
import { graphql } from 'gatsby';

function InfographicVideo({ data: { video, name } }) {
  const containerRef = useRef(null);

  const motionState = useCSSMotion(containerRef);
  const intersected = useIntersectionObserver(containerRef, { triggerOnce: false });

  useSectionTimeSpent(name, containerRef);

  return (
    <div className={classnames('InfographicVideo', motionState)} ref={containerRef}>
      {video?.localFile?.publicURL && (
        <VideoPlayer
          className="infographic-video"
          url={video.localFile.publicURL}
          width="100%"
          height="100%"
          muted
          playsinline
          loop
          playing={intersected}
        />
      )}
    </div>
  );
}

InfographicVideo.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    infographic_items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text_fields: PropTypes.arrayOf(
          PropTypes.shape({
            subtitle: PropTypes.string,
            description: PropTypes.string,
            play_at: PropTypes.shape({
              start: PropTypes.number,
              end: PropTypes.number,
            }),
          }),
        ),
        media: videoPropTypes,
      }).isRequired,
    ),
  }).isRequired,
});

InfographicVideo.defaultProps = {};

export default memo(InfographicVideo);

export const fragments = graphql`
  fragment InfographicVideo on WpPage_Flexiblelayout_FlexibleChildren_InfographicVideo {
    video {
      ...Video
    }
    id
    jumpToLinkTitle
    name
  }

  fragment InfographicVideo_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_InfographicVideo {
    video {
      altText
      localFile {
        publicURL
      }
    }
    id
    jumpToLinkTitle
    name
  }
`;
